<template>
  <div class="news-material">
    <div class="top-btns">
      <el-button
        type="primary"
        :loading="refreshStatus"
        icon="el-icon-refresh"
        @click="refreshMaterial()"
        size="mini"
        v-power="`40-40-10-10`"
        >刷新素材</el-button
      >
    </div>

    <!-- 查询表单 -->
    <div class="search-form">
      <el-form inline size="mini">
        <el-form-item label="编码">
          <el-input v-model="searchForm.mediaCodeLike" clearable />
        </el-form-item>
        <el-form-item label="更新时间">
          <el-date-picker
            v-model="updateTimeRange"
            type="datetimerange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <common-table
      ref="newsMaterial"
      :apiKey="tableConfig.apiKey"
      :autoLoad="tableConfig.autoLoad"
      :search-params="searchForm"
      :columns="tableConfig.columns"
      :options="tableConfig.options"
      :refresh-on-app-change="true"
      :expand-row-keys="tableConfig.expandRowKeys"
      @expand-change="fillNewsItemList"
    >
      <template slot-scope="prop" slot="expand-cell">
        <table class="sub-table" v-if="rowNewsItemData[prop.row.id]" cellspacing="0" >
          <tr class="row title-row">
            <td>序号</td>
            <td>标题</td>
            <td>摘要</td>
            <td>封面图</td>
            <td>操作</td>
          </tr>
          <tr class="row-news-item-list" v-for="(item, index) in rowNewsItemData[prop.row.id].rows" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.digest }}</td>
            <td><el-image style="width: 50px; height: 50px" :src="item.thumbUrl" referrerpolicy="no-referrer" :preview-src-list="[item.thumbUrl]" /></td>
            <td><el-link :href="item.url" target="_blank" type="primary">查看详情</el-link></td>
          </tr>
        </table>
      </template>
    </common-table>

    <el-dialog 
      :title="editDialogInfo.title"
      :visible.sync="editDialogInfo.show"
      :close-on-click-modal="false"
      center
      destroy-on-close>
      <el-form size="mini" label-width="50px" label-suffix=":">
          <el-form-item label="编码" >
              <el-input v-model="editMdeiaCodeForm.mediaCode" clearable />
          </el-form-item>
      </el-form>
      <template #footer>
          <el-button type="primary" size="mini" @click="saveMediaCode">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "newsMaterial",
  data() {
    return {
      refreshStatus: false,
      searchForm: {},
      updateTimeRange: [],
      editDialogInfo: {
        title: '修改编码',
        show: false
      },
      rowNewsItemData: {

      },
      editMdeiaCodeForm: {
        id: null,
        mediaCode: null
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              let today = new Date();
              let year = today.getFullYear();
              let month = today.getMonth() + 1;
              let day = today.getDate();
              let start = new Date(
                year + "-" + month + "-" + day + " 00:00:00"
              );
              let end = new Date(year + "-" + month + "-" + day + " 23:59:59");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableConfig: {
        apiKey: "fw-wx-material-listNewsMaterial",
        autoLoad: true,
        expandRowKeys:[],
        columns: [
          {
            type: 'expand'
          },
          {
            label: "类型",
            prop: "_caption.mediaType",
            width: "50px",
          },
          {
            label: "编码",
            prop: "mediaCode",
            formatter: (row, column, cellValue, index) => {
              const copyMediaCode = async () => {
                try {
                  await this.$copyText(cellValue);
                  this.$message.success('复制成功');
                } catch(e) {
                  this.$message.error('复制[' + cellValue + ']失败');
                }
              }
              let text = cellValue.length >= 50 ? cellValue.substring(0, 50) + '...' : cellValue;
              return (
                  <el-tooltip
                    content={'点击复制：' + cellValue}
                    placement={index == 0 ? "bottom" : "top"}
                  >
                    <span class="copiable-text" onClick={copyMediaCode}>{text}</span>
                  </el-tooltip>
                );
            },
          },
          {
            label: 'MediaId',
            prop: 'mediaId',
            formatter: (row, column, cellValue, index) => {
              const copyMediaCode = async () => {
                try {
                  await this.$copyText(cellValue);
                  this.$message.success('复制成功');
                } catch(e) {
                  this.$message.error('复制[' + cellValue + ']失败');
                }
              }
              let text = cellValue.length >= 50 ? cellValue.substring(0, 50) + '...' : cellValue;
              return (
                <el-tooltip
                    content='点击复制'
                    placement={index == 0 ? "bottom" : "top"}
                  >
                   <span class="copiable-text" onClick={copyMediaCode}>{text}</span>
                  </el-tooltip>
              );
            }
          },
          {
            label: "更新时间",
            prop: "updateTime",
          },
        ],
        options: [
          {
            label: "修改编码",
            powerId: "40-40-10-20",
            handler: (row) => {
              this.editMediaCode(row);
            },
          }
        ],
      },
    };
  },
  methods: {
    copyMediaCode(mediaCode) {
      console.log(mediaCode);
    },
    doSearch() {
      this.$refs.newsMaterial.load(1);
    },
    async refreshMaterial() {
      this.refreshStatus = true;
      try {
        await this.$http.doApi("fw-wx-material-refresh", {
          typeId: 4,
        });
        this.$message.success("刷新成功");
        this.$refs.newsMaterial.refreshCurrent();
      } finally {
        this.refreshStatus = false;
      }
    },
    editMediaCode(row) {
        this.editMdeiaCodeForm.id = row.id;
        this.editMdeiaCodeForm.mediaCode = row.mediaCode;
        this.editDialogInfo.show = true;
    },
    async saveMediaCode() {
        await this.$http.doApi('fw-wx-material-saveMediaCode', this.editMdeiaCodeForm);
        this.$message.success('保存成功');
        this.editDialogInfo.show = false;
        this.$refs.newsMaterial.refreshCurrent();
    },
    async fillNewsItemList(row, expanded) {
      if (expanded.length === 0) {
        delete this.rowNewsItemData[row.id];
      } else {
        let newsItemData = await this.$http.doApi('fw-wx-news-item-listByMatId', {
          matId: row.id
        });
        this.rowNewsItemData[row.id] = newsItemData;
        this.tableConfig.expandRowKeys = [row.id];
      }
    }
  },
  watch: {
    updateTimeRange(newVal) {
      if (newVal && newVal.length > 0) {
        this.searchForm.updateTimeGte = newVal[0];
        this.searchForm.updateTimeLte = newVal[1];
      } else {
        delete this.searchForm.updateTimeGte;
        delete this.searchForm.updateTimeLte;
      }
    },
  },
};
</script>

<style lang="less">
.news-material {
  padding: 10px;
  .top-btns {
    margin-bottom: 1rem;
  }
  .search-form {
    padding: 8px;
  }

  .sub-table {
    width: 96%;
    float: right;

    .title-row {
      background-color: #f5f5f6;
      color: #000000;
      font-weight: 600;
      td {
        padding: .5rem;
      }
    }
  }
}
</style>